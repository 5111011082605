import React from 'react';
import { useFormik } from 'formik';
import { useNavigate, Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { RiMailLine, RiLockPasswordLine } from 'react-icons/ri';
import Typography from '@mui/material/Typography';
import { ButtonRow, FormInput, Loader, useAuth, AuthLayout, Message } from '@omnigenbiodata/ui';

import ROUTES from '../../../../core/constants/routes.constant';

function Login() {
  const navigate = useNavigate();
  const { t } = useTranslation(['portal', 'validation']);
  const { hasError, isBusy, forgotPasswordHasSuccess, signIn } = useAuth();

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email(t('emailInvalid'))
      .required(t('emailRequired', { ns: 'validation' })),
    password: yup.string().required(t('passwordRequired', { ns: 'validation' })),
  });

  const formik = useFormik({
    initialValues: { email: '', password: '' },
    validationSchema,
    onSubmit: (values) => {
      signIn(values.email, values.password);
    },
  });

  const handleCancel = () => {
    navigate(ROUTES.root);
  };

  return (
    <AuthLayout
      title={t('signin.title')}
      text={t('signin.text')}
      hasError={hasError}
      errorTitle={t('alerts.loginFailed.title')}
      errorText={t('alerts.loginFailed.text')}
      dmsa
    >
      <form onSubmit={formik.handleSubmit}>
        {forgotPasswordHasSuccess && <Message>{t('alerts.resetPasswordSuccess')}</Message>}

        <Box mb={4}>
          <FormInput
            error={formik.errors.email}
            name="email"
            label={t('forms.dmEmail')}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            touched={formik.touched.email}
            value={formik.values.email}
            startAdornment={<RiMailLine fontSize="large" />}
          />
          <FormInput
            error={formik.errors.password}
            name="password"
            label={t('forms.password')}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            touched={formik.touched.password}
            value={formik.values.password}
            type="password"
            autocomplete="new-password"
            startAdornment={<RiLockPasswordLine fontSize="large" />}
          />
          <Link to={`${ROUTES.authResetPassword}`}>
            <Typography paragraph variant="body1" align="right" color="textPrimary">
              {t('buttons.forgotPassword')}
            </Typography>
          </Link>
        </Box>
        <ButtonRow
          forwardColor="primary"
          forwardLabel={t('buttons.signIn')}
          onBack={handleCancel}
          buttonSize="large"
          showBack={false}
        />
      </form>
      <Loader isVisible={isBusy} label={t('loaders.check') as string} />
    </AuthLayout>
  );
}

export default Login;
